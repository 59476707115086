//INFO: 화면설명
<script>
import LanguageConverter from '@/util/LanguageConverter'
export default {
  name: '',
  components: {},
  data() {
    return {}
  },
  props: {
    title: String,
    count: Number,
    link: String
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    LanguageConverter
  }
}
</script>

<template>
  <article>
    <div class="wf__search-result-list">
      <h4 class="wf__search-result-title">
        {{ title }}
        <span class="count">({{ count }})</span>
      </h4>
      <router-link class="wf__search-result-link" :to="link">+ {{ LanguageConverter({ en: 'MORE', kr: '더보기' }) }} </router-link>
    </div>
    <div class="divider"></div>
  </article>
</template>

<style scoped>
.wf__search-result-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wf__search-result-title {
  font-size: var(--wf-text-30);
  font-weight: 700;
}
.wf__search-result-title .count {
  color: var(--wf-gray-color);
  font-size: var(--wf-text-16);
  font-weight: 400;
}
.wf__search-result-link {
  font-size: var(--wf-text-14);
  color: var(--wf-gray-color);
}
.divider {
  width: 100%;
  background-color: var(--wf-gray-color);
  height: 2px;
  margin: 0.5rem 0;
}
</style>
