<script setup>
import ContentInModalVue from '@/components/integratedSearch/ContentInModal.vue'
import ModalVue from '@/components/ui/Modal.vue'
import SearchListHeadVue from '@/components/integratedSearch/SearchListHead.vue'
import PlatformBoardListVue from '@/components/ui/PlatformBoardList.vue'
import InfoCardVue from '@/components/ui/InfoCard.vue'
import LoadingVue from '@/components/ui/Loading.vue'

import { searchProject, searchPaper, searchPatent, searchHandover } from '@/api/platform/search'
import { getLab, getManyLab } from '@/api/labs'
import queryStringBuilder from '@/util/queryStringBuilder'
import LanguageConverter from '@/util/LanguageConverter'

import { ref, onMounted, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

const store = useStore()
const route = useRoute()
const router = useRouter()

const app = getCurrentInstance()
const getDate = app.appContext.config.globalProperties.$getDate

const pjtList = ref([])
const totalPjtCounts = ref(0)
const paperList = ref([])
const totalPaperCounts = ref(0)
const patentList = ref([])
const totalPatentCounts = ref(0)
const handoverList = ref([])
const totalHandoverCounts = ref(0)
const labList = ref([])
const totalLabCounts = ref(0)
const labNoList = ref(new Set())
const modalData = ref({})

const queryString = ref('')

const isShowModal = ref(false)
const isLoading = ref(false)

const showModal = () => {
  isShowModal.value = true
}
const closeModal = () => {
  isShowModal.value = false
}

const boardListItemClick = (e) => {
  const target = e.target
  const itemElement = target.matches('li') ? target : target.closest('li')

  const link = itemElement.dataset.link

  const arr = link?.split('/')
  const boardType = arr.at(-2)
  const boardId = arr.at(-1)

  if (boardType === 'pjt') {
    const data = pjtList.value.find((item) => item.projectId === Number(boardId))
    modalData.value = {
      badge: LanguageConverter({ en: 'Project', kr: '프로젝트' }),
      title: data.projectTitleKr,
      boardLink: data.link,
      author: data.projectManager,
      createdAt: getDate(data.createdAt),
      labNo: data.labNo
    }
  } else if (boardType === 'paper') {
    const data = paperList.value.find((item) => item.paperId === Number(boardId))
    // console.log(data)
    modalData.value = {
      badge: LanguageConverter({ en: 'Paper', kr: '논문' }),
      title: data.paperResultTitle,
      boardLink: data.link,
      author: data.paperAuthor,
      createdAt: getDate(data.createdAt),
      labNo: data.labNo
    }
  } else if (boardType === 'patent') {
    const data = patentList.value.find((item) => item.patentId === Number(boardId))
    modalData.value = {
      badge: LanguageConverter({ en: 'Patent', kr: '지재권' }),
      title: data.patentResultTitle,
      boardLink: data.link,
      author: data.patentRegistrant,
      createdAt: getDate(data.createdAt),
      labNo: data.labNo
    }
  } else if (boardType === 'handover') {
    const data = handoverList.value.find((item) => item.handoverId === Number(boardId))
    modalData.value = {
      badge: LanguageConverter({ en: 'Tech Cooperation', kr: '기업협력' }),
      title: data.techName,
      boardLink: data.link,
      author: data.labName,
      createdAt: getDate(data.createdAt),
      labNo: data.labNo
    }
  }

  showModal()
}

const mapLabData = (item) => {
  return {
    src: item.leadResearcherImgUrl,
    data: {
      title: LanguageConverter({ en: item.labNameEn, kr: item.labName }),
      subtitle: LanguageConverter({ en: item.leadResearcherNameEn, kr: item.leadResearcherName }),
      infoList: [
        {
          head: LanguageConverter({ en: 'Institution', kr: '소속기관' }),
          desc: LanguageConverter({ en: item.affiliatedInstitutionName, kr: item.affiliatedInstitutionName })
        },
        {
          head: LanguageConverter({ en: 'MainField', kr: '분야' }),
          desc: LanguageConverter({ en: item.sixTechnologyMedium, kr: item.sixTechnologyMedium })
        }
      ],
      link: `/lab/${item.labNo}`
    }
  }
}

const callLabApi = async () => {
  if (route.query.opt === 'researcherName') {
    const labs = await getManyLab({ leadResearcherName: route.query.q })

    if (!labs.count) {
      totalLabCounts.value = 0
      labList.value = []
      return
    }

    totalLabCounts.value = labs.count
    labList.value = labs.items.map(mapLabData)
    return
  }

  if (route.query.opt === 'keyword') {
    const labs = await getManyLab({ keyword: route.query.q })

    if (!labs.count) {
      totalLabCounts.value = 0
      labList.value = []
      return
    }

    totalLabCounts.value = labs.count
    labList.value = labs.items.map(mapLabData)
    return
  }

  const labTempList = []

  totalLabCounts.value = labNoList.value.size

  for (const labNo of labNoList.value) {
    if (labTempList.length >= 5) break

    const lab = await getLab(labNo)

    if (lab.code === 'DB_DATA_NOT_FOUND') {
      if (totalLabCounts.value > 0) totalLabCounts.value -= 1
      continue
    }
    labTempList.push(lab)
  }

  labList.value = labTempList.map(mapLabData)
}

const callApi = async (apiFunc, param, mapArray) => {
  const data = await apiFunc(param)

  if (!(data.items instanceof Array)) {
    return { count: 0, list: [] }
  }

  const resultList = data.items.reduce((acc, cur, idx) => {
    labNoList.value.add(cur.labNo)

    if (idx > 2) return acc

    acc.push(mapArray(cur))
    return acc
  }, [])

  return { count: data.count, list: resultList }
}

const mapProjectData = (item) => {
  return {
    ...item,
    link: `/community/pjt/${item.projectId}`,
    textContents: {
      title: LanguageConverter({ en: item.projectTitleEn, kr: item.projectTitleKr }),
      subtitle: [
        `(${item.projectNumber})`,
        `${item.projectYear ? new Date(item.projectYear).getFullYear() : ''}`,
        item.projectResearchAgency,
        item.projectMinistry
      ],
      lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
    }
  }
}
const mapPaperData = (item) => {
  return {
    ...item,
    link: `/community/paper/${item.paperId}`,
    textContents: {
      title: item.paperResultTitle,
      subtitle: [
        `(${item.paperResultID})`, //
        `${item.paperPubYear ? new Date(item.paperPubYear).getFullYear() : ''}`,
        item.paperJCType,
        item.paperJournalName
      ],
      lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
    }
  }
}
const mapPatentData = (item) => {
  return {
    ...item,
    link: `/community/patent/${item.patentId}`,
    textContents: {
      title: item.patentResultTitle,
      subtitle: [
        `(${item.patentResultID})`, //
        `${item.patentYear ? new Date(item.patentYear).getFullYear() : ''}`,
        item.patentRegistType,
        item.patentIprType,
        item.patentRegistrant
      ],
      lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
    }
  }
}
const mapHandoverData = (item) => {
  return {
    ...item,
    link: `/community/handover/${item.handoverId}`,
    textContents: {
      title: LanguageConverter({ en: item.techNameEn, kr: item.techName }),
      subtitle: [
        `${item.techTransferYear ? new Date(item.techTransferYear).getFullYear() : ''}`, //
        item.techGrowLevel,
        item.techStatus,
        item.techApplyField
      ],
      lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
    }
  }
}

const searchAction = async (searchColumn, searchKeyword, cb) => {
  const searchParam = {}
  if (searchColumn === 'all') {
    searchParam.type = 'OR'
    for (const condition of store.state.searchStore.conditions) {
      if (condition.value === 'all') continue
      searchParam[condition.value] = route.query.q
    }
  } else {
    searchParam[searchColumn] = searchKeyword
  }

  queryString.value = queryStringBuilder({ q: searchKeyword, opt: searchColumn })

  const project = await callApi(searchProject, searchParam, mapProjectData)
  const paper = await callApi(searchPaper, searchParam, mapPaperData)
  const patent = await callApi(searchPatent, searchParam, mapPatentData)

  if (searchColumn === 'researcherName') {
    searchParam.researcherInfo = searchKeyword
    searchParam.researcherName = null
  }

  const handover = searchColumn === 'scienceClass' ? { count: 0, list: [] } : await callApi(searchHandover, searchParam, mapHandoverData)

  return cb({ project, paper, patent, handover })
}

const loadData = async () => {
  isLoading.value = true

  labNoList.value.clear()

  await searchAction(route.query.opt, route.query.q, ({ project, paper, patent, handover }) => {
    totalPjtCounts.value = project.count
    totalPaperCounts.value = paper.count
    totalPatentCounts.value = patent.count
    totalHandoverCounts.value = handover.count
    pjtList.value = project.list
    paperList.value = paper.list
    patentList.value = patent.list
    handoverList.value = handover.list
  })
  // set lab ref
  await callLabApi()

  isLoading.value = false
}

const cardClickHandler = (data) => {
  const { link } = data
  if (link) router.push(link)
}

onMounted(async () => {
  await loadData()
})

watch(
  () => route.query.q,
  async () => {
    await loadData()
  }
)
watch(
  () => route.query.opt,
  async () => {
    await loadData()
  }
)
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <section class="view-result">
    <h3>
      <span v-if="$store.getters.isEng"> Search results for </span>
      <span class="emphasis">"{{ route.query.q }}"</span>
      <span v-if="!$store.getters.isEng"> 에 대한 검색결과입니다. </span>
    </h3>
  </section>

  <section class="wf__result-list">
    <article v-if="totalPjtCounts !== 0">
      <SearchListHeadVue
        :title="LanguageConverter({ en: 'PROJECT', kr: '프로젝트' })"
        :count="totalPjtCounts"
        :link="`/community/pjt${queryString}`"
      />
      <PlatformBoardListVue :boardList="pjtList" :disableLink="true" @boardListItemClick="boardListItemClick" />
    </article>
    <article v-if="totalPaperCounts !== 0">
      <SearchListHeadVue
        :title="LanguageConverter({ en: 'PAPER', kr: '논문' })"
        :count="totalPaperCounts"
        :link="`/community/paper${queryString}`"
      />
      <PlatformBoardListVue :boardList="paperList" :disableLink="true" @boardListItemClick="boardListItemClick" />
    </article>
    <article v-if="totalPatentCounts !== 0">
      <SearchListHeadVue
        :title="LanguageConverter({ en: 'PATENT', kr: '지재권' })"
        :count="totalPatentCounts"
        :link="`/community/patent${queryString}`"
      />
      <PlatformBoardListVue :boardList="patentList" :disableLink="true" @boardListItemClick="boardListItemClick" />
    </article>
    <article v-if="totalHandoverCounts !== 0">
      <SearchListHeadVue
        :title="LanguageConverter({ en: 'Tech Cooperation', kr: '기업협력 성과' })"
        :count="totalHandoverCounts"
        :link="`/community/handover${queryString.replace('researcherName', 'researcherInfo')}`"
      />
      <PlatformBoardListVue :boardList="handoverList" :disableLink="true" @boardListItemClick="boardListItemClick" />
    </article>

    <article v-if="totalLabCounts !== 0">
      <SearchListHeadVue title="연구실" :count="totalLabCounts" :link="`/researchField/result?labs=${[...labNoList].toString()}`" />
      <div class="wf__cards-wrapper">
        <div class="wf__cards-wrapper-item wf__card" v-for="(card, i) in labList" :key="i">
          <InfoCardVue :src="card.src" :data="card.data" :vertical="true" @onClick="cardClickHandler" />
        </div>
      </div>
    </article>

    <article
      v-if="totalPjtCounts == 0 && totalPaperCounts == 0 && totalPatentCounts == 0 && totalHandoverCounts == 0 && totalLabCounts == 0"
      :style="{ 'text-align': 'center' }"
    >
      데이터가 없습니다.
    </article>
  </section>

  <ModalVue v-if="isShowModal" @closeModal="closeModal">
    <ContentInModalVue :detailData="modalData"></ContentInModalVue>
  </ModalVue>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.view-result {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1.5em 0.5em;

  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-bottom: 2.5em;

  font-size: var(--wf-text-30);
  background-color: var(--wf-gray-600);
}
.view-result h3 {
  max-width: 20em;
  font-weight: 400;
  font-size: inherit;
}
.view-result .emphasis {
  font-weight: 600;
  color: var(--wf-primary-color);
}

.wf__result-list {
  display: flex;
  flex-direction: column;
  gap: 3em;
  font-size: var(--wf-text-16);
}
.wf__cards-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1em;
  font-size: var(--wf-text-16);
  margin-top: 1em;
}
.wf__cards-wrapper-item {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .wf__cards-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .wf__cards-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .wf__cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .wf__cards-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
