<script>
import LanguageConverter from '@/util/LanguageConverter'
import InfoCardVue from '../ui/InfoCard.vue'
import { getLab } from '@/api/labs'

export default {
  components: { InfoCardVue },
  data() {
    return {
      labData: {},
      verticalCard: false,
      ro: new ResizeObserver(this.roCallback)
    }
  },
  methods: {
    LanguageConverter,
    roCallback(entries) {
      for (const entry of entries) {
        this.verticalCard = entry.contentRect.width < 576
      }
    },
    onInfoItemClick(info) {
      window.location.href = `/integratedSearch/integratedSearchResult?q=${info}&opt=keyword`
    }
  },
  props: ['detailData'],
  async mounted() {
    // labNo로 연구실 정보 조회
    const labData = await getLab(this.detailData.labNo)

    this.ro.observe(document.documentElement)

    // 해당 정보로 modal 보여주기
    this.labData = {
      profile: labData.leadResearcherImgUrl,
      title: LanguageConverter({ en: labData.leadResearcherNameEn, kr: labData.leadResearcherName }),
      subtitle: LanguageConverter({ en: labData.labNameEn, kr: labData.labName }),
      link: `/lab/${labData.labNo}`,
      infoList: labData.keyword?.split(', ')
    }
  }
}
</script>

<template>
  <article class="wf__lab-summary" v-if="detailData">
    <section class="wf__lab-summary-header">
      <span class="wf__badge--primary">{{ detailData.badge }}</span>
      <h2 class="wf__lab-summary-header-title">{{ detailData.title }}</h2>
      <div class="wf__lab-summary-header-date">{{ detailData.createdAt }}</div>
    </section>

    <section class="wf__lab-summary-link"><router-link :to="detailData.boardLink">보러가기</router-link></section>

    <section class="wf__lab-summary-body">
      <ul class="wf__profile-summary-body-wrapper">
        <li class="">
          <div class="wf__profile-summary-body-head">저자</div>
          <div class="divider--vertical"></div>
          <p>{{ detailData.author }}</p>
        </li>
        <li class="">
          <div class="wf__profile-summary-body-head">발행일</div>
          <div class="divider--vertical"></div>
          <p>{{ detailData.createdAt }}</p>
        </li>
      </ul>
    </section>

    <section class="wf__lab-summary-profile">
      <InfoCardVue
        class="wf__lab-summary-profile-content"
        :src="labData.profile"
        :data="labData"
        :notList="true"
        :vertical="verticalCard"
        @onInfoItemClick="onInfoItemClick"
        cursor="default"
        maxWidth="150px"
      >
        <router-link :to="labData.link ?? '/'" class="wf__lab-summary-profile-link">연구실바로가기</router-link>
      </InfoCardVue>
    </section>
  </article>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: var(--wf-primary-color);
}

.wf__badge--primary {
  background-color: var(--wf-primary-color);
  color: var(--wf-misty-color);
  border-radius: 9999px;
  padding: 0.25em 2em;
  width: max-content;
  font-size: var(--wf-text-14);
  font-weight: bold;
}
.wf__lab-summary {
  display: flex;
  flex-direction: column;
  gap: 2em;
  text-align: left;
}
.wf__lab-summary-header {
  display: flex;
  flex-direction: column;
  gap: 0.25em;
}
.wf__lab-summary-header-title {
  font-weight: bold;
  margin-top: 0.5em;

  font-size: var(--wf-text-30);

  max-width: 25em;
  max-height: 2.5em;
  overflow: hidden;
  width: 100%;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* ...처리가 일어날 줄 */
  -webkit-box-orient: vertical;
}
.wf__lab-summary-header-date {
  color: var(--wf-gray-color);
  font-size: var(--wf-text-14);
  margin-left: 0.5em;
}
.wf__lab-summary-link {
  padding: 1rem 0;
  border: none;
  border-top: 2px solid var(--wf-gray-color);
  border-bottom: 2px solid var(--wf-gray-color);

  font-size: var(--wf-text-16);
}

.divider--vertical {
  height: 1em;
  width: 1px;
  background-color: var(--wf-gray-color);
  margin: 0 2em;
}
.wf__profile-summary-body-wrapper > li {
  display: flex;
  align-items: center;

  font-size: var(--wf-text-16);
}
.wf__profile-summary-body-head {
  font-weight: bold;
  min-width: 3em;
}
.wf__profile-summary-body-head ~ p {
  display: inline-block;
  max-height: 1.5em;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 1; /* ...처리가 일어날 줄 */
  -webkit-box-orient: vertical;
}
.wf__lab-summary-profile {
  border: none;
  border-top: 2px solid var(--wf-gray-color);
  padding-top: 1em;
  width: 100%;
}
/* .wf__lab-summary-profile-link {
} */
.wf__lab-summary-profile-content {
  margin-left: 0;
  margin-right: 0;
}

:deep(div.info-content) {
  cursor: pointer;
}
</style>
